var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c("b-col", [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "text-search" } }, [
                  _vm._v("Search")
                ]),
                _c("b-form-input", {
                  attrs: { id: "text-search" },
                  on: {
                    keydown: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.setSearch($event)
                    }
                  },
                  model: {
                    value: _vm.filter.q,
                    callback: function($$v) {
                      _vm.$set(_vm.filter, "q", $$v)
                    },
                    expression: "filter.q"
                  }
                })
              ],
              1
            ),
            _c(
              "button",
              { staticClass: "btn btn-primary", on: { click: _vm.setSearch } },
              [_vm._v("Search")]
            )
          ]),
          _c("b-col", [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "status-select" } }, [
                  _vm._v("Status")
                ]),
                _c("c-select", {
                  attrs: {
                    value: _vm.filter.Status,
                    id: "status-select",
                    options: _vm.statusOptions
                  },
                  on: {
                    input: function($event) {
                      _vm.$set(_vm.filter, "Status", $event.target.value)
                      _vm.setSearch()
                    }
                  }
                })
              ],
              1
            )
          ]),
          _c("b-col", [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "owner-select" } }, [
                  _vm._v("Owner")
                ]),
                _c("c-select", {
                  attrs: {
                    value: _vm.filter.UserAccountID,
                    id: "owner-select",
                    options: _vm.taskUsers
                  },
                  on: {
                    input: function($event) {
                      _vm.$set(_vm.filter, "UserAccountID", $event.target.value)
                      _vm.setSearch()
                    }
                  }
                })
              ],
              1
            )
          ]),
          _c("b-col", [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "description" } }, [
                _vm._v("New Task Description:")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newTaskDescription,
                    expression: "newTaskDescription"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  id: "description",
                  type: "email",
                  "aria-describedby": "emailHelp"
                },
                domProps: { value: _vm.newTaskDescription },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.newTaskDescription = $event.target.value
                  }
                }
              })
            ]),
            _c(
              "button",
              { staticClass: "btn btn-primary", on: { click: _vm.createTask } },
              [_vm._v("Create Task")]
            )
          ])
        ],
        1
      ),
      _c(
        "b-row",
        [
          _vm.filterLoaded
            ? _c(
                "b-col",
                [
                  _c("task-list", { attrs: { filter: _vm.activeFilter } }),
                  _c("p", { staticClass: "mt-3 mb-0" }, [
                    _c("em", [_vm._v("(*) No due date set")])
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }