<template lang="pug">
b-container(fluid)
  b-row.mb-3
    b-col
      .form-group
        label(for="text-search") Search
        b-form-input(id="text-search" class="" v-model="filter.q" @keydown.enter="setSearch")
      button.btn.btn-primary(@click='setSearch') Search

    b-col
      .form-group
        label(for="status-select") Status
        c-select(
          :value="filter.Status"
          @input="$set(filter, 'Status', $event.target.value); setSearch()"
          id="status-select"
          :options="statusOptions"
        )
  
    b-col
      .form-group
        label(for="owner-select") Owner
        c-select(
          :value="filter.UserAccountID"
          @input="$set(filter, 'UserAccountID', $event.target.value); setSearch()"
          id="owner-select"
          :options="taskUsers"
        )

    b-col
      .form-group
        label(for='description') New Task Description:
        input#description(v-model='newTaskDescription' type='email' class='form-control' aria-describedby='emailHelp')

      button.btn.btn-primary(@click='createTask') Create Task
    

  b-row
    b-col(v-if='filterLoaded')
      task-list(:filter='activeFilter')

      p.mt-3.mb-0 
        em (*) No due date set

</template>

<script>
  import * as Sentry from "@sentry/vue"

  export default {
    components: {
      TaskList: () => import('@/components/TaskList')
    },
    props: {
      taskAttribute: Object,
    },
    data () {
      return {
        newTaskDescription: '',
        creating: false,
        filterLoaded: false,
        filter: {
          ParentTaskAttributeID: '',
          Status: 'Active',
          UserAccountID: this.$root.$data.me.UserAccountID,
          q: '',
        },
        activeFilter: {
          ParentTaskAttributeID: '',
          Status: 'Active',
          UserAccountID: this.$root.$data.me.UserAccountID,
          q: '',
        },
        statusOptions: [
          { value: 'All', label: 'All'},
          { value: 'Active', label: 'Active states'},
          { value: 'Inactive', label: 'Inactive states'},
          { value: 'Waiting', label: 'Waiting'},
          { value: 'Open', label: 'Open'},
          { value: 'In Progress', label: 'In Progress'},
          { value: 'On Hold', label: 'On Hold'},
          { value: 'Waiting for Manufacturing', label: 'Waiting for Manufacturing'},
          { value: 'Completed', label: 'Completed'},
          { value: 'Resolved', label: 'Resolved'},
        ],
        taskUsers: [],
      }
    },
    watch: {
      activeFilter: {
        deep: true,
        handler () {

        },
      },
    },
    methods: {
      setSearch () {
        /*
        const filter = JSON.parse(JSON.stringify(this.activeFilter))
        delete filter.ParentTaskAttributeID
        delete filter.q

        localStorage.setItem('taskFilter', JSON.stringify(filter))
        */

        Object.assign(this.activeFilter, this.filter)
      },
      async createTask () {
        this.creating = true

        const data = {
          ParentTaskAttributeID: this.taskAttribute.TaskAttributeID,
          AttributeTypeID: 'FE65CC03-9820-42A6-81FE-EDC552681594',
          UserAccountID: this.$root.$data.me.UserAccountID,
          Description: this.newTaskDescription,
          Status: 'Waiting',
        }

        try {
          const response = await this.$http.post('task-attributes', data)
          
          this.$router.push({ name: 'task-detail-overview', params: { id: response.data.TaskAttributeID } })
        } catch (error) {
          this.creating = false

          Sentry.captureException(error)

          this.$notify({
              type: 'danger',
              text: 'An error occurred while creating task, please reload the page and try again.'
            });
        }
      }
    },
    async mounted () {
      // Get users
      const response = await this.$http.get('task-users')
      const userList = response.data.map(user => { return { value: user.UserAccountID, label: user.FullName } })
      userList.unshift({
        label: 'All',
        value: ''
      })

      this.$set(
        this,
        'taskUsers', 
        userList
      )

      let filter = JSON.parse(localStorage.getItem('taskFilter'))
      delete filter.q
      if (!filter) {
        filter = {
          Status: 'Active',
          UserAccountID: this.$root.$data.me.UserAccountID,
        }
      }

      filter.ParentTaskAttributeID = this.taskAttribute.TaskAttributeID
      Object.assign(this.filter, filter)
      this.setSearch()
      this.filterLoaded = true
    }
  }
</script>

<style scoped>

</style>
